<template>
  <cookielaw>
    <div slot-scope="props">
      <p>This site uses 🍪</p>
      <button class="skew" @click="props.accept"><span>I accept</span></button>
      <button class="skew" @click="props.close"><span>Ignore me</span></button>
    </div>
  </cookielaw>
</template>


<script>
import cookielaw from 'vue-cookie-law';

export default ({
    components: {
       cookielaw,
    },
    methods: {

      cookiesAccept : function(){

      },
      cookiesReject : function(){

      }

    }
})
</script>

<style scoped>



</style>