<template>
  <div id="app" v-if="adBlock">
    <div class="ads"><Adsense data-ad-client="ca-pub-6632858291174288" data-ad-slot="4245998666">
    </Adsense></div>
    <h1>Age in days</h1>
    <h4>Enter your date of Birth and get your age in days</h4>
    <datepicker
      :initial-view="initialView"
      placeholder="Select your Date of Birth"
      @selected="dateSelected"
    ></datepicker>
    <div v-if="message != ''" id="result">
      <div v-if="message > 0">
        <p>{{ message }} <span> day<span v-if="message>1">s</span> old ! </span></p>
        <p><span>Next important day in {{ remaining }} day<span v-if="remaining>1">s</span> : {{ nextGoodDay }} days on {{nextGoodDate}} </span></p>
      </div>
      <div v-else>
        <span>Are you born ? </span>
      </div>
    </div>
    <cookiesDisplay></cookiesDisplay>
  </div>
  <div id="app" v-else>
    <AdBlocker></AdBlocker>
  </div>
</template>

<script>
import datepicker from "vuejs-datepicker";

import { detectAnyAdblocker } from "vue-adblock-detector";
import AdBlocker from "./components/AdBlocker.vue";
import cookiesDisplay from "./components/cookiesDisplay.vue";

export default {
  name: "App",
  components: {
    datepicker,
    cookiesDisplay,
    AdBlocker,
  },
  beforeMount() {
    detectAnyAdblocker().then((detected) => {
      if (detected) {
        this.adBlock = false;
      }
    });
  },
  data() {
    return {
      value: "",
      message: "",
      initialView: "year",
      adBlock: true,
      nextGoodDay:0,
      nextGoodDate:0,
      remaining :0
    };
  },
  methods: {
    dateSelected: function (date) {
      var today = new Date();
      var bornTime = new Date(date);
      var days =
        (today.getTime() - bornTime.getTime()) / 60 / 60 / 24 / 1000;
      this.message = Math.trunc(days);
      if(days===0){
        //C'est la fête
      }else{
        if(days>0){
          var nextGoodDay = 5000;
          while(nextGoodDay<days){
           nextGoodDay+=5000; 
          }
          this.nextGoodDay = nextGoodDay ;
          var nextGoodDate = new Date();
          nextGoodDate.setDate(today.getDate() + nextGoodDay - Math.trunc(days) );
          this.nextGoodDate = nextGoodDate.toLocaleDateString();
          this.remaining = nextGoodDay - Math.trunc(days)
        }
      }
    },
  },
};
</script>

<style>
html {
  width: 100%;
  height: 100%;
  background: radial-gradient(white, #3e899c);
}
body {
  width: 100%;
  height: 100%;
  
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: flex;
  align-items: center;
  flex-direction: column;

  text-align: center;
  color: #2c3e50;
  
  width: 100%;
  height: 100%;
}

#result {
  margin-top: 20px;
}
.ads{
  width : 100%;
 
}
</style>
